
import { ref, defineComponent, computed } from 'vue';
import { useReturnRoute } from '@/composables/useReturnRoute';
import { useRoute } from 'vue-router';
import usePoolQuery from '@/composables/queries/usePoolQuery';
import { shortenLabel } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';
import arrow from './table-arrow.svg';

export default defineComponent({
  components: {},
  props: {},
  watch: {},
  setup(props, { emit }) {
    const { getReturnRoute } = useReturnRoute();
    const route = useRoute();
    const id = ref<string>(route.params.id as string);
    const poolQuery = usePoolQuery(route.params.id as string);
    const pool = computed(() => poolQuery.data.value);
    const poolQueryLoading = computed(
      () =>
        poolQuery.isLoading.value ||
        poolQuery.isIdle.value ||
        Boolean(poolQuery.error.value)
    );
    const loadingPool = computed(() => poolQueryLoading.value || !pool.value);

    const creationDate = computed(() => {
      if (pool.value?.createTime) {
        const date = new Date(pool.value.createTime * 1000);
        return date.toLocaleString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
      }
      return '';
    });
    const swapFee = computed(() => {
      if (pool.value?.swapFee) {
        return Number(pool.value.swapFee) * 100;
      }
      return 0;
    });
    const poolOwner = computed(() => {
      if (pool.value?.owner) {
        return shortenLabel(pool.value.owner);
      }
      return '';
    });
    const poolAddress = computed(() => {
      if (pool.value?.id) {
        return shortenLabel(pool.value.address);
      }
      return '';
    });

    const { explorerLinks: explorer } = useWeb3();

    const poolOwnerLink = computed(() => {
      if (pool.value?.owner) {
        return explorer.addressLink(pool.value.owner);
      }
      return '';
    });
    const poolAddressLink = computed(() => {
      if (pool.value?.id) {
        return explorer.addressLink(pool.value.address);
      }
      return '';
    });

    const tableData = computed(() => {
      const onchainTokens = pool.value?.onchain?.tokens || [];
      return Object.keys(onchainTokens).map((address, index) => ({
        address,
        index,
      }));
    });

    function symbolFor(address: string) {
      if (!pool || !pool.value) return '-';
      const symbol = pool.value?.onchain?.tokens?.[address]?.symbol;
      return symbol ? symbol : shortenLabel(address);
    }
    function iconAddresses(tableData) {
      let addresses: string[] = [];
      tableData.forEach(token => {
        addresses.push(token?.address);
      });
      // if (pool != undefined)
      //   return POOLS.Metadata[pool.id]?.hasIcon
      //     ? [pool.address]
      //     : orderedTokenAddresses(pool);
      return addresses;
    }
    return {
      getReturnRoute,
      id,
      pool,
      creationDate,
      swapFee,
      poolOwner,
      poolAddress,
      poolOwnerLink,
      poolAddressLink,
      arrow,
      tableData,
      symbolFor,
      iconAddresses,
      loadingPool,
    };
  },
  mounted() {},
  onBeforeMount() {},
  updated() {},
  data() {
    return {};
  },
  methods: {},

  emits: [],
});
